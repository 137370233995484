var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Mailroom Notifications")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/mailroom/admin/notifications/new","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" New Notification ")],1)],1),_c('v-data-table',{attrs:{"items":_vm.notifications,"headers":_vm.headers,"server-items-length":_vm.notificationCount,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20, 25] }},on:{"click:row":function (arg, ref) {
	var item = ref.item;

	return _vm.$router.push('/mailroom/admin/notifications/' + item._id);
}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.active ? 'Y' : 'N'))]}},{key:"item.targets",fn:function(ref){
var item = ref.item;
return [(item.email && 'include' in item.email && item.email.include)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"margin-right":".2em"}},on),[_vm._v("fal fa-envelope")])]}}],null,true)},[_c('span',[_vm._v("Sends an email")])]):_vm._e(),(item.text && 'include' in item.text && item.text.include)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fas fa-mobile-android-alt")])]}}],null,true)},[_c('span',[_vm._v("Sends a text")])]):_vm._e()]}},{key:"item.lastSent",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.lastSent ? _vm.stringFormatDate(item.lastSent) : '--Not Sent--'))]}},{key:"item.updatedUser",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.updatedUser.last + ', ' + item.updatedUser.preferred))]}},{key:"item.updatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.updatedDate)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }