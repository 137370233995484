<template>
  <v-card>
    <!-- <admin-header></admin-header> -->
    <v-toolbar>
      <v-toolbar-title>Mailroom Notifications</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/mailroom/admin/notifications/new" outlined>
        <v-icon left>fal fa-plus</v-icon>
        New Notification
      </v-btn>
    </v-toolbar>
    <v-data-table :items="notifications" :headers="headers" :server-items-length="notificationCount" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @click:row="(arg, { item }) => $router.push('/mailroom/admin/notifications/' + item._id)">
      <template v-slot:item.active="{ item }">{{ item.active ? 'Y' : 'N' }}</template>
      <template v-slot:item.targets="{ item }">
        <v-tooltip v-if="item.email && 'include' in item.email && item.email.include" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" style="margin-right: .2em">fal fa-envelope</v-icon>
          </template>
          <span>Sends an email</span>
        </v-tooltip>
        <v-tooltip v-if="item.text && 'include' in item.text && item.text.include" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fas fa-mobile-android-alt</v-icon>
          </template>
          <span>Sends a text</span>
        </v-tooltip>
      </template>
      <template v-slot:item.lastSent="{ item }">{{ item.lastSent ? stringFormatDate(item.lastSent) : '--Not Sent--' }}</template>
      <template v-slot:item.updatedUser="{ item }">{{ item.updatedUser.last + ', ' + item.updatedUser.preferred }}</template>
      <template v-slot:item.updatedDate="{ item }">{{ stringFormatDate(item.updatedDate) }}</template>
    </v-data-table>
  </v-card>
</template>
<style scoped>
.v-data-table >>> tbody > tr {
  cursor: pointer !important;
}
</style>
<script>
import { ref, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  components: {
    // AdminHeader: () => import('@/components/mailroom/admin/header')
  },
  setup (props, { root }) {
    const notifications = ref([])
    const notificationCount = ref(0)
    const headers = ref([
      { text: 'Title', value: 'title' },
      { text: 'Active?', value: 'active' },
      { text: 'Sends To', value: 'targets' },
      { text: 'Last Sent', value: 'lastSent' },
      { text: 'Number Sent', value: 'numSent' },
      { text: 'Last Updated By', value: 'updatedUser' },
      { text: 'Last Updated', value: 'updatedDate' }
    ])

    onMounted(() => {
      loadNotifications()
    })

    async function loadNotifications () {
      const query = {
        $limit: 10,
        $populate: 'updatedUser'
      }
      const { data, total } = await root.$feathers.service('mailroom/notification').find({ query })
      notifications.value = data
      notificationCount.value = total
    }

    return {
      notifications,
      notificationCount,
      headers,
      stringFormatDate
    }
  }
}
</script>
